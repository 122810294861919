import { createBrowserHistory } from 'history';
import React, { useContext, useEffect, useState } from 'react';
import { Route, Router, Switch } from 'react-router-dom';

import { ContainerContext } from '../common/container/ContainerContext';
import NotFoundErrorPage from '../common/errorPage/Page404/NotFoundErrorPage';
import PageServiceNotAvailable from '../common/errorPage/PageServiceNotAvailable/PageServiceNotAvailable';
import { UserSystemRoleModelPermissionMap } from '../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { RequirementForm } from '../view/admin/page/requirement/entity/form/RequirementForm';
import { SettingsPage } from '../view/admin/page/settings/SettingsPage';
import { SettingsPageTree } from '../view/admin/page/settings/SettingsPageTree';
import EmptyLayoutRoute from '../view/layout/empty/EmptyLayoutRoute';
import MainLayoutRoute from '../view/layout/main/MainLayoutRoute';
import { isShowAnalytic } from '../view/layout/main/parts/LeftNavigation/LeftNavigationInjects';
import { ApplicationEdit } from '../view/user/page/application/info/ApplicationEdit';
import { ApplicationInfo } from '../view/user/page/application/info/ApplicationInfo';
import { LogoutPage } from '../view/user/page/login/LogoutPage';
import { SignInPage } from '../view/user/page/login/SignInPage';
import { SignInPageWithLogout } from '../view/user/page/login/SignInPageWithLogout';
import { MainPage } from '../view/user/page/main/MainPage';
import { NotificationPage } from '../view/user/page/notification/NotificationPage';
import { ProjectForm } from '../view/user/page/project/form/ProjectForm';
import { ProjectInfo } from '../view/user/page/project/info/ProjectInfo';
import { ProjectListPage } from '../view/user/page/project/list/ProjectListPage';
import { AnalyticsPageMain } from './../view/admin/page/analytics/AnalyticsPageMain';
import { ApplicationAdditionalRoutes } from './ApplicationAdditionalRoutes';
import { ApplicationRouterExtensions } from './ApplicationRouterExtensions';
import { ApplicationRoutes } from './constants/routes';
import { useI18n } from './language/useI18n';

export const appHistory: any = createBrowserHistory();
const ApplicationRouter = () => {
  const container = useContext(ContainerContext);
  const routerExtension = container.get(ApplicationRouterExtensions);
  const { translate, translateAnyway } = useI18n();
  const [settingsPages, setSettingsPages] = useState<
    {
      key: string;
      path: string;
      pageTitle: string;
      component: any;
      allowedPermissions: string[];
    }[]
  >([]);

  const MainPageComponent = MainPage;
  const AnalyticsMainPageComponent = AnalyticsPageMain;
  const isReqAnalyticsShow = useContext(ContainerContext).get(isShowAnalytic);

  const settingsPageTree = container.get(SettingsPageTree);
  const additionalRoutes = container.get(ApplicationAdditionalRoutes);

  useEffect(() => {
    const routers = Object.values(settingsPageTree).reduce(
      (result, item) => {
        result.push(...item.routers);
        return result;
      },
      [] as {
        key: string;
        path: string;
        pageTitle: string;
        component: any;
        allowedPermissions: string[];
      }[],
    );


    setSettingsPages(routers);
  }, [settingsPageTree]);

  return (
    <Router history={appHistory}>
      <Switch>
        <EmptyLayoutRoute exact path={ApplicationRoutes.Login} component={SignInPage} key={'login'} />
        <EmptyLayoutRoute
          exact
          path={ApplicationRoutes.LoginTimeout}
          component={SignInPageWithLogout}
          key={'login-timeout'}
        />
        <EmptyLayoutRoute exact path={ApplicationRoutes.Logout} component={LogoutPage} key={'logout'} />
        <MainLayoutRoute exact path={ApplicationRoutes.Root} component={MainPageComponent} />
        <MainLayoutRoute
          exact
          path={ApplicationRoutes.ProjectAll}
          pageTitle={translate('projectPage.myProjects')}
          component={ProjectListPage}
        />
        <MainLayoutRoute
          exact
          path={ApplicationRoutes.ProjectInfo}
          pageTitle={translate('phrases.projectPage')}
          component={ProjectInfo}
        />
        <MainLayoutRoute
          exact
          path={ApplicationRoutes.ProjectCreate}
          pageTitle={translate('phrases.addProject')}
          component={ProjectForm}
          allowedPermissions={[UserSystemRoleModelPermissionMap['project-not-in-team-permission-create-project']]}
        />
        <MainLayoutRoute
          exact
          path={ApplicationRoutes.ProjectEditApplication}
          pageTitle={translate('phrases.edit')}
          component={ProjectForm}
          allowedPermissions={[]}
        />
        <MainLayoutRoute
          exact
          path={ApplicationRoutes.ProjectEdit}
          pageTitle={translate('phrases.editProject')}
          component={ProjectForm}
          allowedPermissions={[]}
        />
        <MainLayoutRoute
          exact
          path={ApplicationRoutes.SystemCreate}
          pageTitle={translate('phrases.addSystem')}
          component={ApplicationEdit}
          allowedPermissions={[]}
        />

        <MainLayoutRoute
          exact
          path={ApplicationRoutes.ApplicationAll}
          pageTitle={translate('phrases.systems')}
          component={ProjectInfo}
        />
        <MainLayoutRoute
          exact
          path={ApplicationRoutes.ApplicationInfo}
          pageTitle={translate('phrases.systemPage')}
          component={ApplicationInfo}
        />
        <MainLayoutRoute
          exact
          path={ApplicationRoutes.ApplicationRequirement}
          pageTitle={translate('phrases.asRequirements')}
          component={RequirementForm}
        />
        <MainLayoutRoute
          exact
          path={ApplicationRoutes.Settings}
          pageTitle={translate('navigation.settings')}
          component={SettingsPage}
          allowedPermissions={[UserSystemRoleModelPermissionMap['settings-is-allow']]}
        />
        <MainLayoutRoute
          exact
          path={isReqAnalyticsShow() ? '/analytics/system' : '/analytics/learning'}
          pageTitle={translate('navigation.analytics')}
          component={AnalyticsMainPageComponent}
          allowedPermissions={[UserSystemRoleModelPermissionMap['global-allow-any']]}
        />
        <MainLayoutRoute exact path="/notification" pageTitle="Уведомления" component={NotificationPage} />

        {settingsPages.map((settings) => {
          return (
            <MainLayoutRoute
              exact
              key={settings.path}
              path={settings.path}
              pageTitle={translateAnyway(settings.pageTitle)}
              component={settings.component}
              allowedPermissions={settings.allowedPermissions as UserSystemRoleModelPermissionMap[]}
            />
          );
        })}

        {routerExtension.map((routeSettings) => {
          return (
            <MainLayoutRoute
              exact
              key={routeSettings.path as string}
              path={routeSettings.path as string}
              pageTitle={routeSettings.pageTitle}
              component={routeSettings.component}
              allowedPermissions={routeSettings.allowedPermissions}
              showPaddings={routeSettings.showPaddings}
              showNavBar={routeSettings.showNavBar !== undefined ? routeSettings.showNavBar : true}
              fullHeight={routeSettings.fullHeight}
              overflowY={routeSettings.overflowY}
            />
          );
        })}
        <Route exact path="/serviceNotAvailable" component={PageServiceNotAvailable} />
        {additionalRoutes.map(({ Component, key }) => Component)}
        <NotFoundErrorPage />
      </Switch>
    </Router>
  );
};

export default ApplicationRouter;
