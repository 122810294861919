import { ConfigManager } from '../../../../../../application/config/ConfigManager';
import { injectRootService } from '../../../../../../service/RootServiceFactory';
import { ISpecificationCategoryModel } from '../../../../../../service/specification/category/ISpecificationCategoryModel';
import { ISpecificationCategorySearch } from '../../../../../../service/specification/category/ISpecificationCategorySearch';
import { UserSystemRoleModelPermissionMap } from '../../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import TableHeader from '../../../../../design/dataTable/parts/appBar/TableHeader';
import { DataGridDetailDomain } from '../../../../../design/dataTable/parts/detail/DataGridDetailDomain';
import { DataTableDomain } from '../../../../../design/dataTable/store/DataTableDomain';
import { DefaultTableSlots } from '../../../../../design/dataTable/store/DataTableUI';
import { IMainLayoutDomainStore } from '../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { SpecificationCategoryAdminDetailUI } from './detail/SpecificationCategoryAdminDetailUI';
import { SpecificationCategoryModalDomain } from './modal/SpecificationCategoryModalDomain';
import { SpecificationCategoryAdminUI } from './SpecificationCategoryAdminUI';

export class CustomAppBar {
  Toolbar = TableHeader;
}

export class SpecificationCategoryAdminDomain extends DataTableDomain<
  ISpecificationCategoryModel,
  ISpecificationCategorySearch
> {
  public ui: SpecificationCategoryAdminUI;
  constructor(
    public layoutDomain: IMainLayoutDomainStore,
    public rootPrivateServices = injectRootService(layoutDomain.serviceType.value),
  ) {
    super(layoutDomain, rootPrivateServices.specification.category);
    this.ui = new SpecificationCategoryAdminUI(this, layoutDomain);
    this.detailDomain = new DataGridDetailDomain(new SpecificationCategoryAdminDetailUI(this));
    this.modalDomain = new SpecificationCategoryModalDomain(
      rootPrivateServices.specification.category,
      layoutDomain,
      this,
    );
    this.ui.tableSlots.setEntity(new CustomAppBar() as DefaultTableSlots);
    this.ui.tableTitle.setValue(this.i18n.translate('settings.systemSpecificationsTitle'));
    this.ui.tableSubtitle.setValue(this.i18n.translate('settings.categories'));
    this.ui.tableAddButtonName.setValue('Новая категория')
  }

  async setPermissions() {
    await super.setPermissions();

    this.ui.isCanCreate.setValue(
      this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-specifications-create']]),
    );
    this.ui.isCanDelete.setValue(
      this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-specifications-delete']]),
    );
    this.ui.isCanEdit.setValue(
      this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-specifications-update']]),
    );
  }

  async loadDetail() {
    this.detailDomain.ui.isDetail.setValue(true);
    const entitiesDetailSearchResult = await this.rootPrivateServices.specification.entity.search({ limit: 100000 })
    const onlyValuesData = entitiesDetailSearchResult.data.filter(item=> !item.applicationId) || []
    this.detailDomain.ui.detailEntities.setList(onlyValuesData);
    this.detailDomain.ui.detailKey.setValue('categoryId');
  }
}
